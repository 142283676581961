window.addEventListener("scroll", function() {
	const scroll = window.scrollY;
	const backTop = document.getElementById("back-top");
	if (scroll >= 500) {
		backTop.classList.add("active");
	} else {
		backTop.classList.remove("active");
	}
});


function initIntersectionObserver() {
  const scrollDown = document.getElementById("downBtn");
  const links = document.getElementById("links");

  if ( ( window.innerHeight <= 800 ) && ( scrollDown ) ) {
    const observer = new IntersectionObserver(function(entries) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          scrollDown.classList.remove("active");
        } else {
          scrollDown.classList.add("active");
        }
      });
    }, {
      threshold: 0.1
    });

    observer.observe(links);
  }
}

document.addEventListener("DOMContentLoaded", function() {
	const initialScrollY = window.scrollY;
	const scrollDown = document.getElementById("downBtn");
	const header = document.getElementById("header");
	const menuToggle = document.querySelector('.menu-trigger');
	const menu = document.getElementById('menu');

	menuToggle.addEventListener('click', function(menu_e) {
		menu_e.preventDefault();
		header.classList.toggle('active');
		menu.classList.toggle('open');
		menuToggle.classList.toggle('active');
	});

	 if (window.innerHeight <= 800 && scrollDown) {
		initIntersectionObserver();
	}
	else if (scrollDown && initialScrollY <= 600) {
    scrollDown.classList.add("active");
	}

	window.addEventListener("resize", function() {
		if (window.innerHeight <= 800) {
			initIntersectionObserver();
		}
	});

	const backTopLink = document.querySelector('#back-top a');
	backTopLink.addEventListener('click', function(backtop_e) {
		backtop_e.preventDefault();
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	});


	// adjust h1 for logo title

	const schoolLogoTitle = document.getElementById("school-logo-title");
	const schoolLogoTextContent = schoolLogoTitle.textContent;

	// Define a regular expression to match either "French" or "Public"
	const frenchRegex = /(.*)(French.*)/;
	const merrittRegex = /(.*)(Merritt.*)/;
	const acresRegex = /(.*)(Acres.*)/;
	const publicRegex = /(.*)(Public.*)/;
	const boardRegex = /(.*)(Board.*)/;

	// Find the match using the regular expression
	const frenchMatch = schoolLogoTextContent.match(frenchRegex);
	const merrittMatch = schoolLogoTextContent.match(merrittRegex);
	const acresMatch = schoolLogoTextContent.match(acresRegex);
	const publicMatch = schoolLogoTextContent.match(publicRegex);
	const boardMatch = schoolLogoTextContent.match(boardRegex);

	if (frenchMatch) {
		// Create a span for the text before the match
		const beforeSpan = document.createElement('span');
		beforeSpan.textContent = frenchMatch[1];
		beforeSpan.classList.add('french-immersion-title');

		// Create a span for the match with the specified class
		const matchSpan = document.createElement('span');
		matchSpan.textContent = frenchMatch[2];
		matchSpan.classList.add('french-immersion-subtitle');

		// Set the content of the div with the spans
		schoolLogoTitle.innerHTML = '';
		schoolLogoTitle.appendChild(beforeSpan);
		schoolLogoTitle.appendChild(matchSpan);
	}
	else if (merrittMatch) {
		// Create a span for the text before the match
		const beforeSpan = document.createElement('span');
		beforeSpan.textContent = merrittMatch[1];

		// Create a span for the match
		const matchSpan = document.createElement('span');
		matchSpan.textContent = merrittMatch[2];

		// Set the content of the div with the spans
		schoolLogoTitle.innerHTML = '';
		schoolLogoTitle.appendChild(beforeSpan);
		schoolLogoTitle.appendChild(matchSpan);
	}
	else if (acresMatch) {
		// Create a span for the text before the match
		const beforeSpan = document.createElement('span');
		beforeSpan.textContent = acresMatch[1];

		// Create a span for the match
		const matchSpan = document.createElement('span');
		matchSpan.textContent = acresMatch[2];

		// Set the content of the div with the spans
		schoolLogoTitle.innerHTML = '';
		schoolLogoTitle.appendChild(beforeSpan);
		schoolLogoTitle.appendChild(matchSpan);
	}
	else if (publicMatch) {
		// Create a span for the text before the match
		const beforeSpan = document.createElement('span');
		beforeSpan.textContent = publicMatch[1];

		// Create a span for the match
		const matchSpan = document.createElement('span');
		matchSpan.textContent = publicMatch[2];

		// Set the content of the div with the spans
		schoolLogoTitle.innerHTML = '';
		schoolLogoTitle.appendChild(beforeSpan);
		schoolLogoTitle.appendChild(matchSpan);
	}
	else if (boardMatch) {
		// Create a span for the text before the match
		const beforeSpan = document.createElement('span');
		beforeSpan.textContent = boardMatch[1];

		// Create a span for the match
		const matchSpan = document.createElement('span');
		matchSpan.textContent = boardMatch[2];

		// Set the content of the div with the spans
		schoolLogoTitle.innerHTML = '';
		schoolLogoTitle.appendChild(beforeSpan);
		schoolLogoTitle.appendChild(matchSpan);
	}
	else {
		const words = schoolLogoTextContent.split(/\s+/);

		if (words.length >= 5) {
			const beforeSpan = document.createElement('span');
			beforeSpan.textContent = words.shift() + ' ' + words.shift(); // Get the first two words

			const matchSpan = document.createElement('span');
			matchSpan.textContent = words.join(' ');

			schoolLogoTitle.innerHTML = '';
			schoolLogoTitle.appendChild(beforeSpan);
			schoolLogoTitle.appendChild(matchSpan);
		}
		else if (words.length > 3) {
			const beforeSpan = document.createElement('span');
			beforeSpan.textContent = words.shift();

			const matchSpan = document.createElement('span');
			matchSpan.textContent = words.join(' ');

			schoolLogoTitle.innerHTML = '';
			schoolLogoTitle.appendChild(beforeSpan);
			schoolLogoTitle.appendChild(matchSpan);
		}
		else {
			const span = document.createElement('span');
			span.textContent = schoolLogoTextContent;

			schoolLogoTitle.innerHTML = '';
			schoolLogoTitle.appendChild(span);
			schoolLogoTitle.style.marginTop = '10px';
		}
	}

	// JavaScript to handle accordion behavior
	const accordions = document.querySelectorAll('.accordion');

	accordions.forEach(function(accordion) {
		accordion.addEventListener('click', function() {
			// Toggle the "active" class to open/close the panel
			accordion.classList.toggle('active');

			// Toggle the chevron direction
			const chevron = accordion.querySelector('i');
			chevron.classList.toggle('fa-chevron-up');

			const panel = accordion.nextElementSibling;
			if (panel.style.display === 'block') {
				panel.style.display = 'none';
			} else {
				panel.style.display = 'block';
			}
		});
	});


	// // JavaScript to apply styles based on the number of rows in tables
	// const tables = document.querySelectorAll('table');

	// // Function to add a class based on the number of rows
	// function addClassBasedOnRowCount(table) {
	// 	if (table.rows.length >= 5) {
	// 		table.classList.add('table-with-header');
	// 	}
	// }

	// // Iterate over all tables and apply the function
	// tables.forEach(table => {
	// 	addClassBasedOnRowCount(table);
	// });

});
